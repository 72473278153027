<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    :item-text="text"
    :item-value="value"
    :label="placeholder"
    :change="emitChange()"
    :disabled="disabled"
    solo
    flat
    rounded
    dense
    outlined
    no-data-text=""
    color="secondary"
    height="24"
    append-icon="keyboard_arrow_down"
    class="edudash-select"
  />
</template>
<script>

export default {
  name: 'EdudashSelect',
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: () => {},
    },
    text: {
      type: String,
      default: 'name',
    },
    value: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: this.selected,
    };
  },
  methods: {
    emitChange() {
      this.$emit('change', this.selectedItem);
    },
  },
};
</script>
<style lang="scss" scoped>
.edudash-select.v-input ::v-deep {
  .v-input__slot {
    padding: 0 8px 0 16px;
    min-height: 24px !important;
    fieldset {
      border: 2px solid $border-color-thick;
    }
    .v-select__slot {
      .v-select__selection {
        @include font-monteserrat-bold;
        font-size: $font-size-extra-super-small;
        color: $text-color-secondary;
      }
      .v-label {
        @include font-monteserrat-bold;
        position: static !important;
        font-size: $font-size-extra-super-small;
        color: $text-color-secondary;
      }
      .v-input__icon {
        height: 12px;
        .v-icon {
          font-size: $font-size-extra-small;
        }
      }
    }
  }
}
.edudash-select.v-input--is-disabled ::v-deep {
  .v-input__slot {
    background-color: $background-disabled-color;
  }
}
</style>
